import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import style from '../style/Contact.module.css';
import pdfTC from '../assets/pdf/gdpr.pdf';

export default function Contact({ r, content }) {
	const [validated, setValidated] = useState(false);
	const [fullName, setFullName] = useState('');
	const [phone, setPhone] = useState('');
	const [budget, setBudget] = useState('');
	const [option, setOption] = useState('default');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [show, setShow] = useState(false);
	const [checked, setChecked] = useState(false);
	const [verify, setVerify] = useState(false);

	const sendEmail = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const form = e.target;
		if (form.checkValidity() === true) {
			emailjs
				.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form, process.env.REACT_APP_USER_ID)
				.then((result) => {
					// console.log(result.text)
				})
				.catch((error) => {
					console.log(error.text);
				});

			setValidated(false);
			setFullName('');
			setPhone('');
			setBudget('');
			setOption('default');
			setEmail('');
			setMessage('');
			setShow(true);
		} else {
			setValidated(true);
		}
	};

	const handleVerify = (token) => {
		if (token) {
			setVerify(true);
		} else {
			setVerify(false);
		}
	};

	return (
		<section ref={r} id='contact' className={`closeMenuOutside ${style.contactContainer}`}>
			<Container>
				<div className={style.sectionHeader}>
					<h1 className='display-5'>CONTACTEAZA-NE</h1>
				</div>
				<Row className={style.rowContainer}>
					<Col xs='12' lg='4' className='d-flex flex-column justify-content-center'>
						<p className={`h5 ${style.info}`}>{content.info}</p>
					</Col>
					<Col xs='12' lg='4' className='px-lg-4'>
						{show && (
							<div className={`h4 ${style.successMessage}`}>
								Mesajul tau a fost transmis cu succes! Vom reveni catre tine cu un raspuns.
							</div>
						)}

						{show || (
							<Form className={style.form} onSubmit={sendEmail} noValidate validated={validated}>
								<Form.Group className='mb-3' controlId='formBasicName'>
									<Form.Control
										className={style.formInput}
										type='text'
										placeholder='Prenume si Nume*'
										name='fullName'
										required
										value={fullName}
										onChange={(e) => setFullName(e.target.value.replace(/[^a-z- ]/gi, ''))}
									/>

									<Form.Control.Feedback type='invalid' className={style.feedbackText}>
										Acest camp este obligatoriu.
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group className='mb-3' controlId='formBasicEmail'>
									<Form.Control
										className={style.formInput}
										type='email'
										placeholder='Adresa de email*'
										name='email'
										required
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>

									<Form.Control.Feedback type='invalid' className={style.feedbackText}>
										Acest camp este obligatoriu.
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group className='mb-3' controlId='formBasicPhone'>
									<Form.Control
										className={style.formInput}
										type='tel'
										placeholder='Telefon*'
										name='phone'
										minLength={10}
										maxLength={10}
										required
										value={phone}
										onChange={(e) => setPhone(e.target.value.replace(/\D+/g, ''))}
									/>

									<Form.Control.Feedback type='invalid' className={style.feedbackText}>
										Acest camp este obligatoriu.
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group className='mb-3' controlId='formBasicBudget'>
									<Form.Control
										className={style.formInput}
										type='number'
										placeholder='Buget*'
										name='budget'
										minLength={10}
										maxLength={10}
										required
										value={budget}
										onChange={(e) => setBudget(e.target.value.replace(/\D+/g, ''))}
									/>

									<Form.Control.Feedback type='invalid' className={style.feedbackText}>
										Acest camp este obligatoriu.
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group controlId='formBasicMessage'>
									<Form.Control
										as='textarea'
										rows={5}
										placeholder='Detalii proiect*'
										name='message'
										required
										value={message}
										onChange={(e) => setMessage(e.target.value)}
									/>

									<Form.Control.Feedback type='invalid' className={style.feedbackText}>
										Lasa-ne un mesaj.
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Check className={style.formCheck} type='checkbox'>
									<Form.Check.Input type='checkbox' onChange={(e) => setChecked(e.target.checked)} />
									<Form.Check.Label>
										Am citit si sunt de acord cu{' '}
										<a href={pdfTC} target='_blank' rel='noreferrer' className={style.checkLabel}>
											Politica de confidentialitate
										</a>
									</Form.Check.Label>
								</Form.Check>

								<Form.Group className='mb-4 d-flex justify-content-center' controlId='formBasicName'>
									<ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={(token) => handleVerify(token)} />
								</Form.Group>

								<Button disabled={!(checked && verify)} type='submit' variant='dark' className='h6'>
									Trimite
								</Button>
							</Form>
						)}
					</Col>
					<Col xs='12' lg='4' className='d-flex flex-column justify-content-center'>
						<ul className={style.schedule}>
							<li>
								<p className='h5'>Ne poti suna sau scrie pe WhatsApp:</p>
								<p className='h3'>{content.phone}</p>
							</li>
							<li>
								<p className='h5'>Ne poti contacta si la adresa de email:</p>
								<p className='h3'>{content.email}</p>
							</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</section>
	);
}
