import React, { Component } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import logo from '../img/bigLogo.png';

import style from '../style/Menu.module.css';

export default class Menu extends Component {
	render() {
		return (
			<Navbar
				ref={this.props.r}
				id='menu'
				className={style.menuNavbar}
				variant='dark'
				expand='lg'
				fixed='top'
				expanded={this.props.menuExpanded}
			>
				<Container className='flex-row-reverse'>
					<Navbar.Brand as={Nav.Link} className={style.navbarBrand} onClick={() => this.props.onScrollTo(1)}>
						<img src={logo} alt='logo' height={45} />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls={`basic-navbar-nav ${style.navbarToggle}`} onClick={this.props.onMenuShow} />
					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav>
							<Nav.Link
								className={`me-5 text-white text-uppercase ${style.menuItem}`}
								onClick={() => this.props.onScrollTo(1)}
							>
								Acasa
							</Nav.Link>
							<Nav.Link
								className={`me-5 text-white text-uppercase ${style.menuItem}`}
								onClick={() => this.props.onScrollTo(2)}
							>
								Solutii
							</Nav.Link>
							<Nav.Link
								className={`me-5 text-white text-uppercase ${style.menuItem}`}
								onClick={() => this.props.onScrollTo(3)}
							>
								Servicii
							</Nav.Link>
							{/* <Nav.Link
								className={`me-5 text-white text-uppercase ${style.menuItem}`}
								onClick={() => this.props.onScrollTo(7)}
							>
								Intrebari
							</Nav.Link> */}
							<Nav.Link
								className={`me-5 text-white text-uppercase ${style.menuItem}`}
								onClick={() => this.props.onScrollTo(4)}
							>
								Contact
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		);
	}
}
