import React from 'react';
import { motion } from 'framer-motion';
import logoImg from '../img/bigLogo.png';
import style from '../style/Loader.module.css';

export default function Loader({ setLoading }) {
	const container = {
		exit: {
			opacity: '0',
			transition: {
				when: 'afterChildren',
				delayChildren: 0.8,
				duration: 0.4,
			},
		},
	};

	const logo = {
		hidden: {
			scale: 3,
			skew: 30,
			opacity: 0,
		},
		visible: {
			scale: 1,
			skew: 0,
			opacity: 1,
		},
		exit: {
			opacity: 0,
		},
	};

	return (
		<motion.div
			className='loaderContainer'
			variants={container}
			initial='hidden'
			animate='visible'
			exit='exit'
			onAnimationComplete={() => setLoading(true)}
		>
			<motion.img variants={logo} className={`${style.loaderImg}`} src={logoImg} />
		</motion.div>
	);
}
