import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import image from '../img/footerImg.png';
import facebook from '../img/facebook.png';
import pdfPC from '../assets/pdf/gdpr.pdf';
import pdfPCookie from '../assets/pdf/cookies.pdf';

import style from '../style/Footer.module.css';

export default function Footer({ r, content }) {
	return (
		<footer ref={r} className={`closeMenuOutside ${style.footerContainer}`}>
			<Container>
				<Row>
					<Col>
						<div className={style.footerContent}>
							<h2>{content.projectName}</h2>
							<h3>{content.companyName}</h3>
							<h5>{content.projectInfo}</h5>
							<img src={image} alt='footerImg' />
							<ul className={style.social}>
								<li>
									<a href={content.facebookLink}>
										<img src={facebook} alt='facebook' />
									</a>
								</li>
							</ul>
							<ul className={style.legal}>
								<li>
									<a href={pdfPC} target='_blank' rel='noreferrer' className='h6'>
										Politica de confidentialitate
									</a>
								</li>
								<li>
									<a href={pdfPCookie} target='_blank' rel='noreferrer' className='h6'>
										Politica cookie
									</a>
								</li>
								<li>
									<a href='https://anpc.ro/' className='h6'>
										ANPC
									</a>
								</li>
							</ul>
							<p className={style.copyright}>{new Date().getFullYear()} jdsoftware.ro. Toate drepturile rezervate.</p>
						</div>
					</Col>
				</Row>
			</Container>
		</footer>
	);
}
