import React, { Component } from 'react';
import Solutions from './components/Solutions';
import Footer from './components/Footer';
import Header from './components/Header';
import Menu from './components/Menu';
import Contact from './components/Contact';
// import Faq from './components/Faq';
import Prices from './components/Prices';
import Loader from './components/Loader';

import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import axios from 'axios';

import './style/App.css';
import Wip from './components/Wip';

import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_G_TAG);
ReactGA.send('pageview');

const container = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
	},
};

const opacity = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
	},
};

const menu = {
	hidden: {
		opacity: 1,
	},
	visible: {
		opacity: 1,
	},
};

export default class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hasLoaded: false,
			menuExpanded: false,
			settings: null,
		};

		this.refsArray = [];
		for (let i = 0; i < 10; i++) {
			this.refsArray.push(React.createRef());
		}
	}

	setLoading = (e) => {
		this.setState({
			hasLoaded: e,
		});
	};

	handleScrollTo = (index) => {
		if (index > 0) {
			this.setState(
				{
					menuExpanded: false,
				},
				() => {
					const element = this.refsArray[index].current;
					const headerOffset = window.innerWidth > 991 ? 56 : 52;
					const elementPosition = element.getBoundingClientRect().top;
					const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

					window.scrollTo({
						top: offsetPosition,
						behavior: 'smooth',
					});
				}
			);
		}
	};

	handleShowMenu = () => {
		this.setState({
			menuExpanded: !this.state.menuExpanded,
		});
	};

	handleCloseMenuOutside = () => {
		if (this.state.menuExpanded) {
			this.setState({
				menuExpanded: false,
			});
		}
	};

	componentDidMount = () => {
		axios
			.get('./settings.json')
			.then((response) => {
				this.setState({
					settings: response.data,
				});
			})
			.catch((err) => {
				console.log('json err:', err);
			});

		setTimeout(() => {
			const elems = document.getElementsByClassName('closeMenuOutside');

			for (let i = 0; i < elems.length; i++) {
				elems[i].addEventListener('click', this.handleCloseMenuOutside);
			}
		}, 2000);
	};

	render() {
		if (this.state.settings === null) return <div id='loadingPanel'></div>;

		return (
			<>
				<AnimateSharedLayout type='crossfade'>
					<AnimatePresence exitBeforeEnter>
						{!this.state.hasLoaded ? (
							<motion.div key='loaderContainer'>
								<Loader setLoading={this.setLoading} />
							</motion.div>
						) : this.state.settings.mentenance ? (
							<Wip />
						) : (
							<motion.div key='content' variants={container} initial='hidden' animate='visible'>
								<motion.div variants={menu}>
									<Menu
										r={this.refsArray[0]}
										menuExpanded={this.state.menuExpanded}
										onMenuShow={this.handleShowMenu}
										onScrollTo={this.handleScrollTo}
									/>
								</motion.div>
								<motion.div variants={opacity}>
									<Header r={this.refsArray[1]} onScrollTo={this.handleScrollTo} content={this.state.settings} />
								</motion.div>
								<motion.div variants={opacity}>
									<Solutions r={this.refsArray[2]} content={this.state.settings.solutions} />
								</motion.div>
								<motion.div variants={opacity}>
									<Prices r={this.refsArray[3]} onScrollTo={this.handleScrollTo} content={this.state.settings.prices} />
								</motion.div>
								{/* <motion.div variants={opacity}>
									<SmartDelivery r={this.refsArray[6]} onScrollTo={this.handleScrollTo} />
								</motion.div> */}
								{/* <motion.div variants={opacity}>
									<Faq r={this.refsArray[7]} content={this.state.settings.faq} />
								</motion.div> */}
								<motion.div variants={opacity}>
									<Contact r={this.refsArray[4]} content={this.state.settings.contact} />
								</motion.div>
								<motion.div variants={opacity}>
									<Footer r={this.refsArray[5]} content={this.state.settings.footerInfo} />
								</motion.div>
							</motion.div>
						)}
					</AnimatePresence>
				</AnimateSharedLayout>
			</>
		);
	}
}
