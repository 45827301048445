import React, { useState } from "react";
import { Container, Col, Row, Modal } from "react-bootstrap";
import { FaLaptopCode, FaDatabase, FaLaptop } from "react-icons/fa";

import style from "../style/Solutions.module.css";

export default function Solutions({ r, content }) {
  const [show, setShow] = useState(false);
  const [item, setItem] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const icons = {
    0: <FaLaptopCode className={style.solutionIcon} />,
    1: <FaDatabase className={style.solutionIcon} />,
    2: <FaLaptopCode className={style.solutionIcon} />,
    3: <FaLaptop className={style.solutionIcon} />,
  };

  return (
    <section ref={r} id="solutions" className={`closeMenuOutside ${style.solutions}`}>
      <Container className="mb-5">
        <div className={style.sectionHeader}>
          <h1 className="display-5">SOLUTII PENTRU AFACEREA TA</h1>
        </div>
        <div className={style.sectionContent}>
          <p>{content.sectionInfo}</p>
        </div>
        <Row>
          {content.solutionsInfo.map((el, i) => {
            return (
              <Col key={el.name} xs="12" md="12" lg="3" className={style.solutionContainer}>
                <div
                  className={style.solution}
                  onClick={() => {
                    handleShow();
                    setItem(i);
                  }}
                >
                  {icons[i]}
                  <p>{el.name}</p>
                  <div className={style.solutionInfo}>
                    <p>{el.shortInfo}</p>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>{content.solutionsInfo[item].name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content.solutionsInfo[item].info}</Modal.Body>
      </Modal>
    </section>
  );
}
