import React, { Component } from "react";
import { Container, Col, Row, Card, Nav, Button } from "react-bootstrap";

import style from "../style/Prices.module.css";

export default class Prices extends Component {
  render() {
    return (
      <section ref={this.props.r} id="prices" className={`closeMenuOutside ${style.prices}`}>
        <Container>
          <div className={style.sectionHeader}>
            <h1 className="display-5">SERVICIILE NOASTRE</h1>
          </div>

          <Row>
            {this.props.content.pricesInfo.map((item, index) => (
              <Col
                key={`price_${index}`}
                xs="12"
                md={index === this.props.content.length - 1 ? 12 : 6}
                lg="4"
                className={style.pricesColumn}
              >
                <Card className={style.pricesCard} text="white" border="dark">
                  <Card.Body className="text-white" as={Nav.Link}>
                    <Card.Title as="h2" className="text-center py-3 mb-4 h2">
                      {item.title}
                    </Card.Title>

                    <div className={`mb-2 ${style.pricesCardPrice}`}>
                      {typeof item.price === "number" ? (
                        <>
                          <p>DE LA</p>
                          <p>{item.price}</p>
                          <p className="h3">LEI</p>
                        </>
                      ) : (
                        item.price.length > 0 && <p className={style.priceInfo}>{item.price}</p>
                      )}
                    </div>

                    <Card.Subtitle as="h5" className={`text-center mt-2 h5 px-3 ${style.pricesCardSubtitle}`}>
                      {item.description}
                    </Card.Subtitle>

                    <ul className={style.pricesCardList}>
                      {item.stats.map((stat, ind) => (
                        <li key={`stat_${ind}`}>
                          <i>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                            </svg>
                          </i>
                          <span className="h6">{stat}</span>
                        </li>
                      ))}
                    </ul>
                    <Button className={style.cardButton} onClick={() => this.props.onScrollTo(4)}>
                      CONTACTEAZA-NE
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    );
  }
}
