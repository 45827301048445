import React, { Component } from "react";
import { Button } from "react-bootstrap";
import style from "../style/Header.module.css";

export default class Header extends Component {
  render() {
    return (
      <div ref={this.props.r} className={style.header}>
        <h2 className={style.headerCaptionTitle}>{this.props.content.home.title}</h2>
        <h5 className={style.headerCaption}>{this.props.content.home.subTitle}</h5>
        <Button className={style.headerCaptionButton} onClick={() => this.props.onScrollTo(4)}>
          Programeaza o intalnire
        </Button>
      </div>
    );
  }
}
